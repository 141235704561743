:root {
    --blue: #0039a6;
    --red: #DA291C;
}

.loginContainer {
    position: absolute;
    width: 250px;
    height: 220px;
    padding: 30px 20px;
    border-radius: 10px;
    background-color: white;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

form{
    width: 100%;
    height: 100%;
    justify-content: space-evenly; 
}


.loginLogo{
    background: var(--red);
    padding: 5px;
    border-radius: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    width: 95%;
    margin: auto;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.loginBtn{
    background: var(--red);
    color: white;
    border: none;
    padding: 3px;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 5px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.loginBtn:active{
    background: #961b12;
    transition: 200ms;
}

.failure{
    color: red;
    font-size: 12px;
    text-align: center;
}

.loginCancel {
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
}

.loginCancel:hover{
    background-color: #f8f8f8;
    transform: scale(1.1);
}