:root {
    --blue: #0039a6;
    --red: #DA291C;
}

.registerContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 250px;
    height: 350px;
    padding: 25px 20px;
    border-radius: 10px;
    background-color: white;
    margin: auto;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

form{
    width: 100%;
    height: 100%;
    justify-content: space-evenly; 
}


.registerLogo{
    background: var(--blue);
    padding: 5px;
    border-radius: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    width: 95%;
    margin: auto;
    margin-top: 10px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.registerBtn{
    background: var(--blue);
    color: white;
    border: none;
    padding: 3px;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 5px;
    margin: 5px 0px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.registerBtn:active{
    background: #2727a5;
    transition: 200ms;
}

.success{
    color: green;
    font-size: 12px;
    text-align: center;
}
.register-failure {
    margin-bottom: 20px;
}

.failure{
    color: red;
    font-size: 12px;
    text-align: center;
}

.registerCancel {
    position: absolute;
    top: 7px;
    right: 7px;
    cursor: pointer;
}

.registerCancel:hover{
    background-color: #f8f8f8;
    transform: scale(1.1);
}
