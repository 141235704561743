@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&family=Rubik:wght@400;500;700&display=swap');

.mapboxgl-ctrl-geocoder {
    position: fixed;
    font-family: 'Poppins', sans-serif;
    top: 10px;
    left: 90px;
    width: 350px;
    max-width: 350px !important;
    overflow: visible;
    background-color: white;
}

.mapboxgl-ctrl-geocoder--input {
    height: 50px;
    font-size: 18px;
    padding: 6px 45px 6px 15px;
    width: 100%;
    border: none;
    background-color: white;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.mapboxgl-ctrl-geocoder--input:focus {
    outline: none;
}

.mapboxgl-ctrl-geocoder .suggestions {
    position: relative !important;
    max-height: none;
    overflow-y: visible;
    margin: 0;
    padding: 0;
    background-color: white;
}

.mapboxgl-ctrl-geocoder .suggestions > li {
    cursor: pointer;
    padding: 5px 15px;
    margin: 0;
    border-bottom: 1px solid #e0e0e0;
}

.mapboxgl-ctrl-geocoder .suggestions > li:last-child {
    border-bottom: none;
}

.mapboxgl-ctrl-geocoder--icon-search {
    left: auto;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.mapboxgl-ctrl-geocoder--input::placeholder {
    font-size: 18px;
    color: #888;
}

.mapboxgl-ctrl-geocoder--button {
    display: none !important;
}

@media screen and (max-width: 640px) {
    .mapboxgl-ctrl-geocoder {
        left: 75px;
        width: calc(100% - 85px);
        max-width: none !important;
    }
}