@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&family=Rubik:wght@400;500;700&display=swap');

:root {
  --blue: #0039a6;
  --red: #DA291C;
  --white: #ffffff;
  --gray: #404040;
  --light-gray: rgb(185, 184, 184);
  --dark-gray: rgb(88, 87, 87);
  --shadow: 4px 4px 2px rgba(0, 0, 0, 0.3);
  --hover-red: #ae1b10;
  --hover-blue: #004ddd;
}

/* Common Styles */
.button, .submitButton, .login, .register, .help {
  border: none;
  border-radius: 5px;
  color: var(--white);
  cursor: pointer;
  padding: 5px;
}

.button:focus, input:focus, textarea:focus, select:focus {
  outline: none;
}

/* App Title */
.appTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background: linear-gradient(45deg, var(--blue), var(--red));
  padding: min(1.5%, 8px);
  border-radius: 30px;
  font-size: 1.2em;
  box-shadow: var(--shadow);
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: auto;
  min-width: 250px;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Card Styles */
.card {
  width: 215px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
}

label {
  width: max-content;
  color: var(--red);
  font-size: 13px;
  margin-top: 5px;
}

.desc {
  font-size: 14px;
}

.star {
  color: gold;
}

.username {
  font-size: 14px;
}

.date {
  font-size: 12px;
  color: var(--gray);
}

/* Form Styles */
form {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--dark-gray);
}

.pinForm {
  width: 97%;
}

input, textarea {
  border: none;
  border-bottom: 1px solid var(--gray);
}

input::placeholder,
textarea::placeholder {
  font-size: 12px;
  color: var(--light-gray);
}

textarea {
  font-size: 90%;
  resize: none;
}

.submitButton {
  background-color: var(--red);
}

/* Button Styles */
.buttons {
  position: absolute;
  top: 10px;
  right: 10px;
}

.button.location {
  position: fixed;
  bottom: 150px;
  right: 20px;
  background-color: var(--white);
  border-radius: 4px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  z-index: 1000;
}

.button.location:hover {
  background-color: #f0f0f0;
}

.button.location svg {
  width: 70%;
  height: 70%;
  color: var(--gray);
}

/* User Info */
.userInfo {
  position: fixed;
  top: 20px;
  right: 20px;
  background: linear-gradient(45deg, var(--blue), var(--red));
  color: var(--white);
  padding: 7px;
  border-radius: 10px;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  min-width: 150px;
  max-width: 300px;
  box-shadow: var(--shadow);
  z-index: 1000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userInfo .welcome-text {
  display: flex;
  align-items: center;
}

.userInfo .icons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 5px;
}

.userInfo .icon {
  cursor: pointer;
  font-size: 1.2rem;
}

/* Help */
.help {
  position: absolute;
  display: flex;
  align-items: center;
  color: var(--white);
  justify-content: space-evenly;
  right: 90px;
  top: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  background-color: green;
  padding: 6px 10px;
  box-shadow: var(--shadow);
}

.helpList {
  font-size: 0.7rem;
  font-weight: normal;
  padding: 2px;
}

.help:hover {
  background-color: rgb(1, 100, 1);
}

/* Login/Register */
.login,
.register {
  margin-top: 10px;
  margin-right: 10px;
  padding: 12px 14px;
  box-shadow: var(--shadow);
}

.login {
  background-color: var(--red);
  margin-right: 20px;
}

.login:hover {
  background-color: var(--hover-red);
}

.register {
  background-color: var(--blue);
}

.register:hover {
  background-color: var(--hover-blue);
}

/* Sidebar */
.sidebar-toggle {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1002;
  background-color: var(--white);
  border: none;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.sidebar-toggle svg {
  font-size: 2rem;
  color: var(--gray);
  transition: transform 0.3s ease-in-out;
}

.sidebar-toggle.open svg {
  transform: rotate(90deg);
}

.sidebar {
  position: fixed;
  top: 80px;
  left: 20px;
  width: 50px;
  background-color: transparent;
  transition: opacity 0.3s, transform 0.3s;
  opacity: 0;
  transform: translateY(-10px);
  pointer-events: none;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar.open {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.sidebar-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-out, background-color 0.3s ease;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.sidebar-item:hover {
  background-color: #f0f0f0;
  transform: scale(1.05);
}

.sidebar-item svg {
  font-size: 1.5rem;
  color: var(--gray);
  transition: transform 0.3s ease-out;
}

/* Mapbox Controls */
.mapboxgl-ctrl-bottom-right {
  bottom: 40px !important;
  right: 11px !important;
}

.mapboxgl-ctrl-attrib {
  position: fixed !important;
  bottom: 0 !important;
  right: 0 !important;
  z-index: 1000;
}

.mapboxgl-ctrl-attrib-inner {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2px 5px;
  border-top-left-radius: 4px;
  font-size: 10px;
}

.mapboxgl-ctrl-group {
  margin-bottom: 10px;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.mapboxgl-ctrl-group button {
  width: 40px;
  height: 40px;
}

.mapboxgl-ctrl-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.mapboxgl-ctrl-icon > span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
}

.mapboxgl-ctrl-icon > span > svg {
  width: 100%;
  height: 100%;
}

.mapboxgl-ctrl-geocoder .suggestions {
  position: relative !important;
  max-height: 250px; 
  overflow-y: auto;
}

.mapboxgl-ctrl-geocoder .suggestions > li {
  cursor: pointer;
  padding: 10px 10px 10px 45px;
}

.mapboxgl-ctrl-geocoder--icon-search {
  top: 13px;
}

.mapboxgl-ctrl-geocoder--button {
  top: 3px;
}

/* Animations */
@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.sidebar.open .sidebar-item {
  animation: slideIn 0.3s ease-out both;
}

.sidebar.open .sidebar-item:nth-child(1) { animation-delay: 0.1s; }
.sidebar.open .sidebar-item:nth-child(2) { animation-delay: 0.2s; }
.sidebar.open .sidebar-item:nth-child(3) { animation-delay: 0.3s; }
.sidebar.open .sidebar-item:nth-child(4) { animation-delay: 0.4s; }
.sidebar.open .sidebar-item:nth-child(5) { animation-delay: 0.5s; }

/* Responsive Design */
@media screen and (max-width: 640px) {
  .appTitle {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1em;
    padding: 5px 10px;
    min-width: 200px;
  }

  .sidebar-toggle {
    top: 10px;
    left: 10px;
  }

  .sidebar {
    top: 70px;
    left: 10px;
  }

  .userInfo {
    top: 60px;
  }

  .mapboxgl-ctrl-geocoder {
    left: 75px;
    width: calc(100% - 85px);
    max-width: none !important;
  }
}